.inputWrapper {
  display: flex;
  align-items: center;
}

.disableFld {
  pointer-events: none !important;
  background: #b6b6b670 !important;
  user-select: none !important;
  opacity: 0.7;
}

.column-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.flex-1{
  flex: 1;
}

@media (max-width: 500px) {
  .inputWrapper {
    flex-direction: column;
  }
}
