.excel-reader-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.file-input-container {
  flex: 1;
  position: relative;
  width: 100%;
}

.custom-file-label {
  display: block;
  width: 100%;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.upload-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}
