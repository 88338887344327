@media print {
  .print * {
    visibility: hidden !important;
    display: none !important;
    margin-top: -10% !important;
}
body {
    font-size: 23px !important;
  }
  .logo{
      margin-top: 35px !important;
      height: 120px !important;
  }
  .inputClass {
    border: none !important;
    text-align: center !important;
  }
  .noteClass {
    border: none !important;
  }
  .table-column-to-hide {
    display: none !important;
  }
}
