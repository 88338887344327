@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700);
.bgImg{
    width: 100%;
    height: 100vh !important;
    background-image: url("/assets/backgroundImg.jpg");
    background-size: cover;
    background-position:center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.wrapper img {
    display: block;
    margin: auto;
    margin-top: 17px;    
}
.wrapper span {
    display: block;
    display: flex;
    margin-top: 10px;
    justify-content: center;
}
  
.input-wrapper {
    width: 100%;
    display: flex;
    border-radius: 7px;
    align-items: center;
    border: 1px rgba(230, 227, 227, 0.575) solid;
    /* outline: none !important; */
    padding-left: 8px;
    margin-bottom:15px;
}

.logoText{
  font-family: "Poppins" ;
  font-size:12px;
}

.forgot{
    color: black;
    cursor: pointer;
}

.icon {
    width: 8%;
}

.input-field {
    /* flex: 1; */
    padding: 10px 5px 10px 0px;
    border: none;
    width: 90%;
    border-color: grey;
    margin-bottom: 10;
    outline: none;
}
.input-wrapper:focus-within  {
    border: 1px rgba(128, 128, 128, 0.575) solid !important;
    /* background-color: aquamarine; */
}
.bgImg{
    width: 100%;
    height: 100vh !important;
    background-image: url("/assets/backgroundImg.jpg");
    background-size: cover;
    background-position:center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.wrapper img {
    display: block;
    margin: auto;
    margin-top: 17px;    
}
.wrapper span {
    display: block;
    display: flex;
    margin-top: 10px;
    justify-content: center;
}
  
.input-wrapper {
    width: 100%;
    display: flex;
    border-radius: 7px;
    align-items: center;
    border: 1px rgba(230, 227, 227, 0.575) solid;
    /* outline: none !important; */
    padding-left: 8px;
    margin-bottom:15px;
}

.logoText{
    font-family: "Poppins" ;
    font-size:12px;
  }
  
.forgotbtn{
    cursor: pointer;
}

.icon {
    width: 8%;
}

.input-field {
    /* flex: 1; */
    padding: 10px 5px 10px 5px;
    border: none;
    width: 90%;
    border-color: grey;
    margin-bottom: 10;
    outline: none;
}
.input-wrapper:focus-within  {
    border: 1px rgba(128, 128, 128, 0.575) solid !important;
    /* background-color: aquamarine; */
}
.bgImg{
    width: 100%;
    height: 100vh !important;
    background-image: url("/assets/backgroundImg.jpg");
    background-size: cover;
    background-position:center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.wrapper img {
    display: block;
    margin: auto;
    margin-top: 17px;    
}
.wrapper span {
    display: block;
    display: flex;
    margin-top: 10px;
    justify-content: center;
}
  
.input-wrapper {
    width: 100%;
    display: flex;
    border-radius: 7px;
    align-items: center;
    border: 1px rgba(230, 227, 227, 0.575) solid;
    /* outline: none !important; */
    padding-left: 8px;
    margin-bottom:15px;
}

.logoText{
    font-family: "Poppins" ;
    font-size:12px;
  }
  
.forgotbtn{
    cursor: pointer;
}

.icon {
    width: 8%;
}

.input-field {
    /* flex: 1; */
    padding: 10px 5px 10px 5px;
    border: none;
    width: 90%;
    border-color: grey;
    margin-bottom: 10;
    outline: none;
}
.input-wrapper:focus-within  {
    border: 1px rgba(128, 128, 128, 0.575) solid !important;
    /* background-color: aquamarine; */
}
.main_Td {
  padding: 0;
}
:focus-visible {
  border-color: #aaa !important;
  outline: #aaa !important;
}
.dataTables_filter {
  margin-right: auto !important;
}
.inputWapper {
  display: flex;
  align-items: center;
  border: 1px black solid;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
}

.inputWapper .input {
  /* border: none; */
  outline: none;
  width: 86%;
}

#example,
#customerTable,
#flavorTable,
#agentTable,
#shipTable,
#itemsTable,
#shipReceivingTable,
#userRoleTable,
#usersTable,
#invoiceTable {
  width: -webkit-fill-available !important;
}

@media (max-width: 808) {
  .inputWrapper {
    font-size: 12px;
  }
}

.iconWapper img {
  height: 15px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

#example_wrapper .dataTables_paginate .paginate_button.current {
  margin-top: -8px;
  background: #007bff !important;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #007bff !important;
  background-color: #007bff !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  background-color: inherit !important;
}

.dataTables_wrapper .dataTables_paginate {
  display: flex;
}

.inputImgWapper {
  position: absolute;
  right: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-left: 1px black solid;
}

.inputImgWapper img {
  width: 100%;
  height: 100%;
}

.my-custom-scrollbar {
  position: relative;
  height: 550px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

th,
td {
  padding: 8px 14px;
  text-align: center !important;
}
tr {
  border-bottom: 1px solid lightgray !important;
}
input[type="number"] {
  /* text-align: center !important; */
  margin: auto  !important;
}
.freeze-table tr:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  top: 0; 
  z-index: 1;
}
.freeze-table tr:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  top: 40px !important;
  z-index: 1;
  background: white;
}
.freeze-table tr:nth-child(3) {
  top: 85px !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background: white;
}
.freeze-table tr:nth-child(4) {
  top: 130px !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background: white;
}
.bg-freeze-color{
  background: white;
}
.freeze-table tr:nth-child(5) {
  top: 175px !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background: white;
}
.freeze-table tr:nth-child(6) {
  top: 220px !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background: white;
}
.freeze-table tr:nth-child(7) {
  top: 265px !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background: white;
}

.data-row {
  background-color: yellow;
}

.Table {
  width: 76vw;
  white-space: nowrap;
  margin-top: 10px;
  padding-bottom: 200px !important;
  overflow-x: auto;
  display: block !important;
  border-collapse: collapse;
  border: 1px solid lightgray !important;
}
.Table-Editable_cell {
  border-right: 1px solid lightgray !important;
}
.Table-flavors_wapper {
  width: 90% !important;
  overflow-x: auto;
  display: block !important;
}
/* table, td, th {
    border: 1px solid lightgray !important;
} */

.Table tr:first-child th {
  border: 0px solid lightgray !important;
}
/* .Table {
    wid: 100%;
} */
.Table_flavor_view {
  background: red !important;
}

.Table_text {
  font-size: 1.2rem;
  font-weight: 500;
}

.Table-Editable_input {
  text-align: center;
  border: 0px;
  width: 80px !important;
}

.Table-Editable_Rate_input {
  text-align: center;
  border: 0px;
  width: 70px !important;
}

.focused {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

/* ********Btn classes********** */
.Table_addNewRowBtn {
  margin: 7px 10px;
  padding: 9px 16px;
  border: none;
  border-radius: 6px;
  transition: 0.5 ease-in-out;
  background: #007bff;
  color: #ffff;
}
.Table_addNewRowBtn:hover {
  opacity: 0.6;
}
.Table_addNewRowBtn:active {
  translate: 0 2px;
}

/* *****scroll bar******** */
.Table::-webkit-scrollbar {
  width: 12px;
  height: 6px;
}

/* Track */
.Table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px !important;
}

/* Handle */
.Table::-webkit-scrollbar-thumb {
  background: rgb(18, 92, 228);
  border: none !important;
  border-radius: 20px;
}

/* Handle on hover */
.Table::-webkit-scrollbar-thumb:hover {
  background: rgba(18, 92, 228, 0.6);
}

.freeze-table {
  table-layout: fixed !important;
  width: 100% !important;
  border-collapse: collapse !important;
}

/* .freeze-table th:first-child,
.freeze-table td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 2 !important;
  background-color: white;
} */

.freeze-table .freeze-col {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  z-index: 2 !important;
  background-color: white;
}
.freeze-table .freeze-col-input {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 0 !important;
  background-color: white;
}

.left-20 {
  left: 20% !important;
}

.stick-btn {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 2px !important;
  right: 0;
  top: 5px;
  z-index: 1;
}

/* ************ custom golbal classes **************** */

.d_flex {
  display: flex !important;
}
.hidden_elem {
  opacity: 1 !important;
  visibility: hidden !important;
}

.searchInput {
  min-width: 230px !important;
  width: -webkit-max-content !important;
  width: max-content !important;
}
.searchInput::-webkit-input-placeholder {
  text-align: left !important;
}

.Table_Btn_disable {
  opacity: 0.4 !important;
  pointer-events: none;
}
/* ************* Edit Invoice ************** */
.Table .col-one-freeze {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white
}
.Table .col-second-freeze {
  position: -webkit-sticky;
  position: sticky;
  left: 16rem;
  background-color: white
}
.borderHide {
  border-bottom: 0px !important;
  border-block-style: none !important;
  border-style: none !important;
}
.modal-header .btn-close {
  -webkit-filter: invert(1) !important;
          filter: invert(1) !important;
}

/* modalStyles.css */

.print-heading {
  display: none; /* Hide by default */
}

.table-container {
  max-height: 500px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

@media print {
  .print-heading {
    display: block; /* Show only in print view */
    text-align: center; /* Center the heading in print view */
    margin-bottom: 8px;
  }

  .print-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px; /* Adjust font size for printing */
  }

  .print-table th,
  .print-table td {
    border: 1px solid #dee2e6;
    padding: 8px;
    text-align: left;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
}

.disableFld {
  pointer-events: none !important;
  background: #b6b6b670 !important;
  -webkit-user-select: none !important;
          user-select: none !important;
  opacity: 0.7;
}

.column-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 6px;
  gap: 6px;
}

.flex-1{
  flex: 1 1;
}

@media (max-width: 500px) {
  .inputWrapper {
    flex-direction: column;
  }
}

.excel-reader-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.file-input-container {
  flex: 1 1;
  position: relative;
  width: 100%;
}

.custom-file-label {
  display: block;
  width: 100%;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.upload-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}

/* customLoader.css */
.custom-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: background-color 0.3s;
}

.custom-loader-container.active {
  display: flex;
}

.custom-loader {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.main_Td{
    padding: 0
}

/* .main_input{
    width: 100% ;
    height: 54px;
} */

.inputWapper{
    display: flex;
    align-items: center;
    border: 1px black solid;
    position: relative;    
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
}

.inputWapper .input {
    /* border: none; */
    outline: none;
    width: 86%;
    
} 

@media (max-width:808){
    .inputWrapper{
        font-size: 12px;
    }
}


.iconWapper img{
    height: 15px;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}

/* .iconWapper{
    display:flex;
    align-items: center;
    gap: 3px;
    
}

.iconWapper img{
    flex: 1;
    height:24px ;
    
} */

/* .img-main{
    background-color: black;
    width: 100%;
} */

.inputImgWapper{
    position: absolute;
    right:0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    border-left: 1px black solid;
}

.inputImgWapper img {
    width: 100%;
    height: 100%;
}
.main_Td {
  padding: 0;
}
:focus-visible {
  border-color: #aaa !important;
  outline: #aaa !important;
}

.inputWapper {
  display: flex;
  align-items: center;
  border: 1px black solid;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
}

.inputWapper .input {
  /* border: none; */
  outline: none;
  width: 86%;
}

@media (max-width: 808) {
  .inputWrapper {
    font-size: 12px;
  }
}

.iconWapper img {
  height: 15px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  margin-top: -8px;
}

.dataTables_wrapper .dataTables_paginate {
  display: flex;
}

.inputImgWapper {
  position: absolute;
  right: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-left: 1px black solid;
}

.inputImgWapper img {
  width: 100%;
  height: 100%;
}

.my-custom-scrollbar {
  position: relative;
  height: 550px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.inputWrapper{
    display: flex;
    align-items: center;
}



@media (max-width:676){
    .inputWrapper{
        flex-direction: column;
    }
}
.inputWrapper{
    display: flex;
    align-items: center;
}



@media (max-width:520px){
    .inputWrapper{
        flex-direction: column;
    }
}
.inputWrapper{
    display: flex;
    align-items: center;
}



@media (max-width:520px){
    .inputWrapper{
        flex-direction: column;
    }
}
.inputWrapper{
    display: flex;
    align-items: center;
}



@media (max-width:520px){
    .inputWrapper{
        flex-direction: column;
    }
}
.flavors{
    background-color: rgba(236, 236, 236, 0.705);
    width: auto;
    border: 1px solid darkgray;
    border-radius: 100px;
    padding: 6px 10px;
    text-align: center;
    align-items: center;
    transition: 0.2s ease-out;
    cursor: pointer;
}
.flavors:hover {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}
.dot{
    background: green;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    display: block;
    margin-top: 10px;
}
.dot2{
    background: rgb(221, 71, 71);
    width: 10px;
    border-radius: 50%;
    height: 10px;
    display: block;
    margin-top: 10px;
}
.txt{
    margin-top: -10px;
}
.main{
    width: auto;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.img_Border{
    border-radius: 13px !important;
    width: 20vh !important;
    height: 19vh !important ;
    margin:0 !important;

}
@media print {
  .print * {
    visibility: hidden !important;
    display: none !important;
    margin-top: -10% !important;
}
body {
    font-size: 23px !important;
  }
  .logo{
      margin-top: 35px !important;
      height: 120px !important;
  }
  .inputClass {
    border: none !important;
    text-align: center !important;
  }
  .noteClass {
    border: none !important;
  }
  .table-column-to-hide {
    display: none !important;
  }
}

.inputWrapper{
    display: flex;
    align-items: center;
}

.disableFld {
    pointer-events: none !important;
    background: #b6b6b670 !important;
    -webkit-user-select: none !important;
            user-select: none !important;
    opacity: 0.7;
}

@media (max-width:470px){
    .inputWrapper{
        flex-direction: column;
    }
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
   }
   
   .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
            user-select: none;
   }
   

   .checkmark {
    position: relative;
    top: 3px;
    /* left: 50px; */
    height: 23px;
    width: 23px;
    background: #6260602e;
    border-radius: 7px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.30), 0px 1px 1px rgba(0,5);
   }
   

   .container input:checked ~ .checkmark {
    background-image: linear-gradient(#5cb85c,#5cb85c)     }

   
   .checkmark:after {
    content: "";
    position: absolute;
    display: none;
   }
   
   .container input:checked ~ .checkmark:after {
    display: block;
   }
   

   .container .checkmark:after {
    left: 0.45em;
    top: 0.20em;
    width: 0.24em;
    height: 0.6em;
    border: solid white;
    border-width: 0 0.15em 0.15em 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
   }
#root {
  width: 100vw;
  height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
body {
  overflow-x: hidden !important;
}

.hideNumber_controls::-webkit-outer-spin-button,
.hideNumber_controls::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
.ExportDropdown {
  top: 3px !important;
  left: -22px !important;
}
thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  top: 56% !important;
}
table.dataTable thead > tr > th.sorting:after {
  opacity: 1.125 !important ;
}
table.dataTable thead > tr > th.sorting_asc:before {
  opacity: 0.6 !important;
}

.z-index_3 {
  z-index: 3 !important;
}
#example,
#customerTable,
#flavorTable,
#agentTable,
#shipTable,
#itemsTable,
#shipReceivingTable,
#userRoleTable,
#usersTable,
#invoiceTable {
  white-space: nowrap !important;
}

