.borderHide {
  border-bottom: 0px !important;
  border-block-style: none !important;
  border-style: none !important;
}
.modal-header .btn-close {
  filter: invert(1) !important;
}

/* modalStyles.css */

.print-heading {
  display: none; /* Hide by default */
}

.table-container {
  max-height: 500px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

@media print {
  .print-heading {
    display: block; /* Show only in print view */
    text-align: center; /* Center the heading in print view */
    margin-bottom: 8px;
  }

  .print-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px; /* Adjust font size for printing */
  }

  .print-table th,
  .print-table td {
    border: 1px solid #dee2e6;
    padding: 8px;
    text-align: left;
  }
}
